import React, { useState, useRef, useEffect } from "react";
import "./HoldToConfirmButton.css";

const HoldToConfirmButton = ({
  onComplete,
  duration = 2000,
  text = "Hold to Confirm",
  completedText = "Confirmed!",
  width = "200px",
}) => {
  const [isHolding, setIsHolding] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);

  // Handle mouse down (or touch start)
  const handleStart = () => {
    setIsHolding(true);
    startTimeRef.current = Date.now();

    timerRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current;
      const newProgress = Math.min((elapsed / duration) * 100, 100);
      setProgress(newProgress);

      if (newProgress >= 100) {
        handleComplete();
      }
    }, 10);
  };

  // Handle mouse up (or touch end)
  const handleEnd = () => {
    if (!isCompleted) {
      clearInterval(timerRef.current);
      setIsHolding(false);
      setProgress(0);
    }
  };

  // Complete action when fully held
  const handleComplete = () => {
    clearInterval(timerRef.current);
    setIsCompleted(true);
    onComplete?.();
  };

  // Reset button after animation completes
  useEffect(() => {
    if (isCompleted) {
      const resetTimer = setTimeout(() => {
        setIsHolding(false);
        setProgress(0);
        setIsCompleted(false);
      }, 1500);

      return () => clearTimeout(resetTimer);
    }
  }, [isCompleted]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  // Dynamic style for progress bar
  const progressBarStyle = {
    width: `${progress}%`,
    transition: isHolding ? "none" : "width 0.3s ease-out",
  };

  // Dynamic style for button width (can't use Tailwind for arbitrary values)
  const buttonStyle = {
    width: width,
    height: "48px",
  };

  return (
    <button
      className={`relative overflow-hidden rounded-lg font-medium text-white transition-all ${
        isCompleted ? "bg-green-500" : "bg-gray-200"
      }`}
      style={buttonStyle}
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
      disabled={isCompleted}
    >
      {/* Background fill indicator */}
      <div
        className="absolute left-0 top-0 h-full bg-indigo-600"
        style={progressBarStyle}
      />

      {/* Button text */}
      <span className="relative z-10 block text-center">
        {isCompleted ? completedText : text}
      </span>
    </button>
  );
};

export default HoldToConfirmButton;
