import React from "react";
// import styles from "./ApplicationTracker.module.css";
import HoldToConfirmButton from "../../components/HoldToConfirmButton";

export default function ApplicationTracker() {
  return (
    <div>
      <HoldToConfirmButton
        onComplete={() => {}}
        duration={1500}
        text="Hold to Submit"
        completedText="Submitted!"
        fillColor="#3b82f6"
      />
    </div>
  );
}
