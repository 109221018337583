import React, { useState } from "react";
import styles from "./PageContainer.module.css";
import Nav from "./Nav";
import Social from "./Social";
import Name from "../pages/Homepage/components/Name";

export default function PageContainer({ children }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  return (
    <div
      className={styles.container}
      onMouseMove={(event) => handleMouseMove(event)}
      style={{
        "--mouse-x": `${mousePosition.x}`,
        "--mouse-y": `${mousePosition.y}`,
      }}
    >
      <div className={styles.centerContainer}>
        <div className={styles.topBar}>
          <Name />
          <Social />
          <Nav />
        </div>
        {children}
      </div>
    </div>
  );
}
